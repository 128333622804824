
import { defineComponent, ref } from 'vue';
import { ElMessage } from 'element-plus';

import { useCommentsReports, useBanComment, useRejectReportComment } from '@/composables/api';
import { CommentIdOptions } from '@/services/api';

import { CommentStatus } from '@/interfaces/Comment';

import SearchBox from '@/components/keyword-searching/Index.vue';

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const { data, isLoading, isFetching, refetch } = useCommentsReports({ page, keyword });
    const { isLoading: isBanLoading, mutate: banMutate } = useBanComment();
    const { isLoading: isRejectReportLoading, mutate: rejectMutate } = useRejectReportComment();

    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const banComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      banMutate({ commentId }, {
        onSuccess() {
          refetch.value();

          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    const rejectReportComment = ({ commentId }: CommentIdOptions, index: Number) => {
      statusIndex.value = index;
      rejectMutate({ commentId }, {
        onSuccess() {
          refetch.value();

          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    return {
      page,
      data,
      CommentStatus,
      isLoading,
      isFetching,
      isBanLoading,
      banComment,
      isRejectReportLoading,
      rejectReportComment,
      statusIndex,
      searchKeyword
    };
  }
});
